function Hero() {
    return (
    <>
   <section className="hero-section style-3 d-flex align-items-center" id="intro">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-md-5 d-md-block">
                  <div className="hero-image-box text-center">
                   <a href="/">  <img src="assets/img/hero/profile.jpg" alt="Images" /></a>
                  </div>
               </div>
               <div className="col-md-7">
                  <div className="hero-content-box">
                     <span className="hero-sub-title">Hi, I am Rajendra.</span>
                     <h1 className="hero-title">Bringing ideas to life with Laravel, Vue.js, and React</h1>

                     <div className="hero-image-box d-md-none text-center">
                        <img src="assets/img/hero/me.png" alt="" />
                     </div>
                     <p className="lead">
                     I have over 8 years of experience as a Front end and 5 years as a full stack developer, specializing in JavaScript, Vue.js, React, Laravel, Bootstrap, Tailwind, CSS, HTML, MySQL, Vuex, Next.js, and WordPress. My projects include coaching consultant tools, invoice systems, e-commerce websites, CMS, and React-based applications. I use Jira for project management and GitHub for version control, ensuring efficient and organized workflows. 
                     </p>
                     <div className="header-media mt-3  d-lg-inline-flex">
                        <ul className="ul-reset social-icons">
                           {/* <li>
                              <a href="#link"><i className="fa-brands fa-twitter"></i></a>
                           </li>
                           <li>
                              <a href="#link"><i className="fa-light fa-basketball"></i></a>
                           </li> */}
                           <li>
                              <a href="https://www.linkedin.com/in/rajendra-nagar-464580170/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                           </li>
                           <li>
                              <a href="https://github.com/rajcracker" target="_blank" rel="noreferrer"><i className="fa-brands fa-github"></i></a>
                           </li>
                           <li>
                           <a href="https://api.whatsapp.com/send/?phone=%2B919950105566&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp"></i></a>
                           </li>
                           {/* <li>
                           <a href="mailto:sharkweb.in@gmail.com" target="_blank" rel="noreferrer"><i className="fa-brands fa-envelope-square"></i></a>
                           </li> */}
                          
                        </ul>
                  </div>
                     <div className="button-box d-flex flex-wrap align-items-center">
                        <a href="rajendra-nagar.pdf" rel="download" target="_blank" className="btn tj-btn-secondary">Resume</a>
                        <a href="tel:+919950105566" className="btn tj-btn-primary">Lets talk : +91 9950105566</a>
                     </div>
                     
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
    );
}

export default Hero;