function MyServices() {
    return (
        <>
          <section className="service-section" id="services-section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="section-header text-center">
                     <h2 className="section-title wow fadeInUp" data-wow-delay=".3s">{'Elevate your online presence'}</h2>
                     <p className="wow fadeInUp text-uppercase" data-wow-delay=".4s">{'with personalized full-stack development expertise.'}</p>
                  </div>
               </div>
            </div>
            <div className="row">
            <div className="col-lg-6">
                  <div className="service-item wow fadeInRight" data-wow-delay=".4s">
                     {/* <div className="service-icon">
                        <i className="flaticon-ui-design"></i>
                     </div> */}
                     <div className="service-content">
                        <h5 className="title"><button  className="modal-popup">{'UI Development'}</button>
                        </h5>
                        <div className="desc">
                           <p>{ "Elevate your UI with cutting-edge React and Next.js development. I'll create sleek, high-performance interfaces tailored to your needs."}</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6">
                  <div className="service-item wow fadeInRight" data-wow-delay=".3s">
                     {/* <div className="service-icon">
                        <i className="flaticon-skill-development"></i>
                     </div> */}
                     <div className="service-content">
                        <h5 className="title"><button className="modal-popup">{'Web Development'}</button>
                        </h5>
                        <div className="desc">
                           <p>{"Elevate your web presence with expert, responsive development tailored to your needs. Let's build something exceptional together!"}</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6">
                  <div className="service-item wow fadeInLeft" data-wow-delay=".3s">
                     {/* <div className="service-icon">
                        <i className="flaticon-code"></i>
                     </div> */}
                     <div className="service-content">
                        <h5 className="title"><button className="modal-popup">{ 'Vue.Js development'}</button>
                        </h5>
                        <div className="desc">
                           <p>{ "Let's bring your vision to life with scalable and maintainable code. Contact me today for top-notch Vue.js solutions!"}</p>
                        </div>
                     </div>
                  </div>
               </div>
               
               <div className="col-lg-6">
                  <div className="service-item wow fadeInLeft" data-wow-delay=".4s">
                     {/* <div className="service-icon">
                        <i className="flaticon-browser"></i>
                     </div> */}
                     <div className="service-content">
                        <h5 className="title"><button  className="modal-popup">{'Product Development'}</button>
                        </h5>
                        <div className="desc">
                           <p>{"Unlock your product's potential with top-notch development expertise. Together, we'll create standout market solutions!"}</p>
                        </div>
                     </div>
                  </div>
               </div>
               
               <div className="col-lg-6">
                  <div className="service-item wow fadeInLeft" data-wow-delay=".4s">
                     <div className="service-content">
                        <h5 className="title"><button  className="modal-popup">{'WordPress Development'}</button>
                        </h5>
                        <div className="desc">
                           <p>{"Transform your website with professional WordPress development. Let's build a stunning, functional, and SEO-friendly site together!"}</p>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-lg-6">
                  <div className="service-item wow fadeInLeft" data-wow-delay=".4s">
                     <div className="service-content">
                        <h5 className="title"><button  className="modal-popup">{'Laravel Development'}</button>
                        </h5>
                        <div className="desc">
                           <p>{"Achieve your development goals with expert Laravel services. Let's create feature-rich, high-performance applications for your business."}</p>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </section>
        </>
    )
}

export default MyServices;