
import Preloader from "./components/Preloader";
import Header from "./components/Header";
import Hero from "./components/Hero";
import RollArea from "./components/RollArea";
import RecentWork from "./components/RecentWork";
import MyServices from "./components/Services";
import Statics from "./components/Statics";
import StartProject from "./components/StartProject";
import Footer from "./components/Footer";
import Skill from "./components/Skill";
import Faq from "./components/Faq";
function App() {
  return (
    <>
   <Preloader></Preloader>
   {/* <Header></Header> */}
   <main className="site-content" id="content">
    <Hero/>
    <RollArea/>
    <RecentWork/>
    <MyServices/>
    <Skill/>
    <Statics/>
    <Faq/>
    <StartProject/>
    <Footer/>
   
   </main>
   </>
  );
}

export default App;
