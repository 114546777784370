function StartProject() {
    return (
    <>
    <section className="text-section">
         <div className="container">
            <div className="row">
               <div className="col-12">
                  <div className="section-header">
                     <div className="heading-left">
                        <p className="wow fadeInUp" data-wow-delay=".3s">Want to start a project?</p>
                        <h2 id="anim" className="section-title wow fadeInUp" data-wow-delay=".4s">Let’s have a chat</h2>
                     </div>
                     <div className="chat-mail wow fadeInRight" data-wow-delay=".5s">
                        <a className="link" href="mailto:sharkweb.in@gmail.com">sharkweb.in@gmail.com 
                           </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
    )
}

export default StartProject;