function Faq() {
    return (
    <>
       <section className="faq-section" id="faq-section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="section-header text-center">
                     <h2 className="section-title wow fadeInUp" data-wow-delay=".3s">Your Questions and Answers</h2>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <div className="accordion tj-faq" id="faqOne">
                     <div className="accordion-item active wow fadeInUp" data-wow-delay=".4s">
                        <button className="faq-title" type="button" data-bs-toggle="collapse" data-bs-target="#faq-1"
                           aria-expanded="true">
                           What is your experience and background in development?
                        </button>
                        <div id="faq-1" className="collapse show" data-bs-parent="#faqOne">
                           <div className="accordion-body faq-text">
                              <p>I have over 8 years of experience as a full stack developer, working on various projects across different industries.</p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item wow fadeInUp" data-wow-delay=".5s">
                        <button className="faq-title collapsed" type="button" data-bs-toggle="collapse"
                           data-bs-target="#faq-2" aria-expanded="false">
                           What kind of projects have you worked on before? Can you provide examples?
                        </button>
                        <div id="faq-2" className="collapse" data-bs-parent="#faqOne">
                           <div className="accordion-body faq-text">
                              <p>I have worked on a range of projects, including:
                                 <ul>
                                    <li>Coaching Consultant Actionable Tools: Developed an application to help coaches track and manage client progress, set goals, and provide actionable feedback. </li>
                                    <li>Invoice Systems: Created a custom invoicing system that automates billing, tracks payments, and generates financial reports for small businesses. </li>
                                    <li>E-commerce Websites: Built e-commerce platforms with features such as product catalogs, shopping carts, payment gateways, and order management. </li>
                                    <li>Content Management Systems (CMS): Developed custom CMS solutions that allow clients to manage their website content easily and efficiently. </li>
                                    <li>React-Based Applications: Created dynamic and responsive single-page applications using React, improving user experience and performance. </li>
                                 </ul>

</p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item wow fadeInUp" data-wow-delay=".6s">
                        <button className="faq-title collapsed" type="button" data-bs-toggle="collapse"
                           data-bs-target="#faq-3" aria-expanded="false">
                           What are your primary skills and technologies you work with?
                        </button>
                        <div id="faq-3" className="collapse" data-bs-parent="#faqOne">
                           <div className="accordion-body faq-text">
                              <p>My primary skills include JavaScript, Vue.js, Laravel, Bootstrap, Tailwind, CSS, HTML, MySQL, Vuex, Next.js, WordPress, Canva, Figma, and React.</p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item wow fadeInUp" data-wow-delay=".7s">
                        <button className="faq-title collapsed" type="button" data-bs-toggle="collapse"
                           data-bs-target="#faq-4" aria-expanded="false">
                           How do you keep your skills updated with the latest trends and technologies?
                        </button>
                        <div id="faq-4" className="collapse" data-bs-parent="#faqOne">
                           <div className="accordion-body faq-text">
                              <p>I regularly take online courses, attend webinars, and participate in developer communities to stay up-to-date with the latest trends and technologies.
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item wow fadeInUp" data-wow-delay=".8s">
                        <button className="faq-title collapsed" type="button" data-bs-toggle="collapse"
                           data-bs-target="#faq-5" aria-expanded="false">
                           How do you typically manage your projects and ensure timely delivery?
                        </button>
                        <div id="faq-5" className="collapse" data-bs-parent="#faqOne">
                           <div className="accordion-body faq-text">
                              <p>I use project management tools like Trello and Jira to track progress and ensure timely delivery. I also break down the project into smaller tasks and set clear milestones.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
    )
}

export default Faq;