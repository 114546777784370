function Statics() {
    return (
        <>
        <section className="counter-section">
         <div className="container">
            <div className="row">
               <div className="col-12">
                  <div className="funfact-area">
                     <div className="row">
                        <div className="col-6 col-lg-3">
                           <div
                              className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center wow fadeInUp"
                              data-wow-delay=".3s">
                              <div className="number"><span className="odometer" data-count="8">8</span></div>
                              <div className="text">Years of <br />Experience</div>
                           </div>
                        </div>
                        <div className="col-6 col-lg-3">
                           <div
                              className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center wow fadeInUp"
                              data-wow-delay=".4s">
                              <div className="number"><span className="odometer" data-count="50">50</span>+</div>
                              <div className="text">Project <br />Completed</div>
                           </div>
                        </div>
                        <div className="col-6 col-lg-3">
                           <div
                              className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center wow fadeInUp"
                              data-wow-delay=".5s">
                              <div className="number"><span className="odometer" data-count="4">4</span>+</div>
                              <div className="text">Happy <br />Organizations</div>
                           </div>
                        </div>
                        <div className="col-6 col-lg-3">
                           <div
                              className="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center wow fadeInUp"
                              data-wow-delay=".6s">
                              <div className="number"><span className="odometer" data-count="5">5</span>+</div>
                              <div className="text">Core <br />Skillset</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
        </>
    )
}
export default Statics;