

function Footer() {
    return (
    <>
      
    <footer id="contact" className="tj-footer-area style-3">
      <div className="container">
         <div className="row">
            <div className="col-md-12 text-center">
               <div className="header-media text-center">
                  <ul className="ul-reset social-icons">
                     {/* <li>
                        <a href="#Link"><i className="fa-brands fa-twitter"></i></a>
                     </li>
                     <li>
                        <a href="#Link"><i className="fa-light fa-basketball"></i></a>
                     </li> */}
                     <li>
                        <a href="https://www.linkedin.com/in/rajendra-nagar-464580170/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                     </li>
                     <li>
                        <a href="https://github.com/rajcracker" target="_blank" rel="noreferrer"><i className="fa-brands fa-github"></i></a>
                     </li>
                     <li>
                        <a href="https://api.whatsapp.com/send/?phone=%2B919950105566&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp"></i></a>
                     </li>
                  </ul>
               </div>
               {/* <div className="footer-logo-box">
                  <a href="#Link">{'me.rajnagar1989@gmail.com'}</a>
               </div> */}
               <div className="footer-menu">
                  <nav>
                     <ul>
                        <li><a href="about-light.html">About</a></li>
                        <li><a href="services.html">Services</a></li>
                        <li><a href="portfolio.html">Portfolios</a></li>
                        <li><a href="contact.html">Contact</a></li>
                     </ul>
                  </nav>
               </div>
               <div className="copy-text">
                  <p>&copy; 2024 All rights reserved</p>
               </div>
            </div>
         </div>
      </div>
   </footer>
    </>
    )
}

export default Footer;