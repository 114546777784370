function Skill() {
    return (
    <>
     <section className="skills-section style-3" id="skills-section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="section-header text-center">
                     <h2 className="section-title wow fadeInUp" data-wow-delay=".3s">My Skills</h2>
                     <p className="text-uppercase wow fadeInUp" data-wow-delay=".4s">
                        {/* Offerd Services */}
                     </p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                  <div className="skills-widget d-flex flex-wrap justify-content-center align-items-center">
                     <div className="skill-item wow fadeInUp" data-wow-delay=".3s">
                        <div className="skill-inner">
                           <div className="icon-box">
                              <img src="assets/img/icons/Vue.js_Logo_2.svg" alt="" />
                           </div>
                           <div className="number">92%</div>
                        </div>
                        <p>Vue Js</p>
                     </div>
                     <div className="skill-item wow fadeInUp" data-wow-delay=".4s">
                        <div className="skill-inner">
                           <div className="icon-box">
                              <img src="assets/img/icons/laravel-2.svg" alt="" />
                           </div>
                           <div className="number">80%</div>
                        </div>
                        <p>Laravel</p>
                     </div>
                     <div className="skill-item wow fadeInUp" data-wow-delay=".5s">
                        <div className="skill-inner">
                           <div className="icon-box">
                              <img src="assets/img/icons/html-5.svg" alt="" />
                           </div>
                           <div className="number">95%</div>
                        </div>
                        <p>HTML</p>
                     </div>
                     <div className="skill-item wow fadeInUp" data-wow-delay=".6s">
                        <div className="skill-inner">
                           <div className="icon-box">
                              <img src="assets/img/icons/wp.svg" alt="" />
                           </div>
                           <div className="number">85%</div>
                        </div>
                        <p>WordPess</p>
                     </div>
                     <div className="skill-item wow fadeInUp" data-wow-delay=".7s">
                        <div className="skill-inner">
                           <div className="icon-box">
                              <img src="assets/img/icons/react.svg" alt="" />
                           </div>
                           <div className="number">75%</div>
                        </div>
                        <p>React</p>
                     </div>
                     <div className="skill-item wow fadeInUp" data-wow-delay=".8s">
                        <div className="skill-inner">
                           <div className="icon-box">
                              <img src="assets/img/icons/js.svg" alt="" />
                           </div>
                           <div className="number">73%</div>
                        </div>
                        <p>JavaScript</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
    )
}

export default Skill;