function RecentWork() {
    return (
    <>
   <section className="project-section mt-5" id="works-section">
         <div className="container">
            <div className="row">
               <div className="col-12">
                  <div className="section-header style-2">
                     <h2 className="section-title wow fadeInUp" data-wow-delay=".4s">Recent Projects</h2>
                     <p className="text-danger">All listed projects have been completed during my tenure at Cracker Digital Media.</p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <div className="project-content-area featured wow fadeInUp" data-wow-delay=".5s">
                     <div className="project-left-content">
                        <div className="project_content">
                           <span className="subtitle">Product</span>
                           <h3 className="title"><button data-mfp-src="#bbportfolio-wrapper"
                                 className="modal-popup">Actionable</button></h3>
                           <p className="desc">
                           Behavior Change Technology to make your learning stick. For Consultants, Change Professionals & Learning & Development teams.

                           </p>
                        </div>
                        <ul className="project_tags">
                           <li>{ 'State Management for complex logic (Vuex)'}</li>
                           <li>{ 'Improve performance using vite'}</li>
                           <li>{ 'Migrated from Vue2 to Vue3'}</li>
                           <li>{ 'API endpoints & integrations to vue side'}</li>
                           <li>{ 'PHP Unit Tests'}</li>
                           <li>{ 'Laravel policy authorization'}</li>
                        </ul>
                        {/* <div className="desc">
                           <p>“The service was excellent. Template example is the next killer app.”</p>
                        </div> */}
                        <div className="project-feature-item">
                           <div className="project-text">
                             <a href="https://actionable.co/" target="_blank" className="btn tj-btn-secondary" rel="noreferrer">{ 'Visit Website'}</a>
                           </div>
                        </div>
                     </div>
                     <div className="project-wrapper">
                        <img src="assets/img/project/project-1.png" alt="Images" />
                     </div>
                  </div>
               </div>
            </div>
            <div className="row bottom-content">
               <div className="col-lg-7">
                  <div className="project-content-area wow fadeInUp" data-wow-delay=".6s">
                     <div className="project-wrapper">
                        <img src="assets/img/project/project-2.png" alt="Images" />
                     </div>
                     <div className="project-left-content">
                        <div className="project_content">
                           <span className="subtitle">News & Insight App</span>
                           <h3 className="title">{'WD Capital'}
                           </h3>
                           <p className="desc">{'CMS / Contentful Integration'}</p>
                        </div>
                        <ul className="project_tags">
                           <li>{ 'Build with Next Js Framework(React)'}</li>
                           <li>{ 'Contentful API Integration'}</li>
                        </ul>
                        <a href="https://www.wdcapital.ca/" target="_blank" className="btn tj-btn-secondary" rel="noreferrer">{ 'Visit Website'}</a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="project-content-area wow fadeInUp" data-wow-delay=".7s">
                     <div className="project-wrapper">
                        <img src="assets/img/project/project-3.png" alt="Images" />
                     </div>
                     <div className="project-left-content">
                        <div className="project_content">
                           <span className="subtitle">{'E Commerce'}</span>
                           <h3 className="title"><button data-mfp-src="#bbportfolio-wrapper"
                                 className="modal-popup">Jewels Unboxed</button></h3>
                           <p className="desc">{'Jewelry Collection Woocommerce Store'}</p>
                        </div>
                        <ul className="project_tags">
                           <li>{'Woocommerce'}</li>
                           <li>{'WordPress'}</li>
                        </ul>
                        <a href="https://jewelsunboxed.com/" target="_blank" className="btn tj-btn-secondary" rel="noreferrer">{ 'Visit Website'}</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
    )
}

export default RecentWork;